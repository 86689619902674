<template>
  <v-card>
    <!-- <div class="d-flex"> -->
      <!-- <div> -->
        <v-list dense>
          <v-list-item-group v-model="periodo">
            <v-list-item v-for="periodos in predefinidos" :value="periodos.value">
              <v-list-item-content>
                <v-list-item-title>{{ periodos.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      <!-- </div> -->
      <!-- <div>
        <v-date-picker :value="formatDate(periodo)" @input="(v) => periodo = parseDate(v, true)"></v-date-picker>
      </div> -->
    <!-- </div> -->
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js"

export default {
  props: {
    value: Number
  },
  data() {
    return {
      predefinidos: [
        { name: 'Ayer',         value: [Date.now() - (1000 * 3600 * 24 * 1)] },
        { name: 'Hace 7 días',  value: [Date.now() - (1000 * 3600 * 24 * 7)] },
        { name: 'Hace 15 días', value: [Date.now() - (1000 * 3600 * 24 * 15)] },
        { name: 'Hace 1 mes',   value: [Date.now() - (1000 * 3600 * 24 * 30)] },
        { name: 'Hace 3 mes',   value: [Date.now() - (1000 * 3600 * 24 * 30 * 3)] },
        { name: 'Hace 6 mes',   value: [Date.now() - (1000 * 3600 * 24 * (365 / 2))] },
        { name: 'Hace 1 año',   value: [Date.now() - (1000 * 3600 * 24 * 365)] },
      ]
    }
  },
  computed: {
    periodo: {
      get() { return this.value },
      set(v) { this.$emit('input', parseDate(v, true)) }
    }
  },
  methods: {
    parseDate,
    formatDate(v) {
      if (v) {
        let date = parseDate(v, false, false);
        let timestamp = parseDate(v, true);
        let daysDifference = Math.floor((Date.now() - timestamp) / 1000 / 60 / 60 / 24)
        return daysDifference
        const [day, month, year] = date.split(' ')
        return [year, month, day].join('-')
      } else {
        return null
      }
    }
  }
}
</script>

<style>
</style>